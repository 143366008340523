/*globals $*/
'use strict';

const getSvg = () => import("./external/functions/svg");
const getHeaderFunctions = () => import("./external/functions/header");

import Dropzone from "dropzone";

/* DETECT MOBILE */
var isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    isMobile = true;
}

Dropzone.autoDiscover = false;

$( document ).ready(function() {
    //LOAD SVG IF NEEDED
    if($(".svg").length > 0) {
        getSvg().then(svgModule => {
            svgModule.prepareSvg();
        });
    }

    $("#artist-form :input").prop("disabled", true);
    $("#release-edit-form :input").prop("disabled", true);
    $("#release_track-edit-form :input").prop("disabled", true);

    $('body').on('change', '[id^="photos"]', function(e){
        $('#section_image_preview').empty();
        var text = '';


        var length = $("input[name='photo_link_array[]']").map(function(){
            return $(this).val();
        }).get().length;

        var fileName = e.target.files[0].name;
        for( var i = 0; i < e.target.files.length; i++){
            $('#section_image_preview').append(
                '<img id="preview-' + (i+length) +'" src="#" class="image_preview" />'
            );

            var output = document.getElementById('preview-' + (i+length));
            output.src = URL.createObjectURL(event.target.files[(i)]);
            output.onload = function() {
                URL.revokeObjectURL(output.src) // free memory
            }

            if((i) < 4){
                text = text + e.target.files[i].name  + ', ';
            }else if((i) === 5) {
                text = text + e.target.files[i].name + '...';
            }else{

            }
        }
        $('#label_photos').html(text);
    });


    $('body').on('click', '[id="master_delete"]', function (e) {
        $('#track_upload_master').removeClass('hidden');
        $('#master').remove();
        $('#track_master_error').addClass('hidden');
        $('#track_master').removeClass('hundreds-validation__input_error')
    });


    $('body').on('click', '[id="toggle-hamburger--open"]', function (e) {
        $('#toggle-hamburger--open').addClass('hidden');
        $('#toggle-hamburger--close').removeClass('hidden');
        $('#toggle-menu').removeClass('hidden');
    });


    $('body').on('click', '[id="toggle-hamburger--close"]', function (e) {
        $('#toggle-hamburger--open').removeClass('hidden');
        $('#toggle-hamburger--close').addClass('hidden');
        $('#toggle-menu').addClass('hidden');
    });


    $('body').on('change', '[id^="select_member_country-"]', function(e){
        var id = $(this).attr('data-key');
        $('#member_country-' + id).val($(this).val());
    });

    $('body').on('change', '[id^="select_member_province-"]', function(e){
        var id = $(this).attr('data-key');
        $('#member_province-' + id).val($(this).val());
    });

    $('body').on('click', '[id^="deleteVideo_"]', function (e) {
        $('#video-item-' + $(this).attr('data-id')).remove();
    });

    $('body').on('click', '[id^="deletePhoto_"]', function (e) {

        $('#preview-' + $(this).attr('data-id')).remove();
        $('#photo_link-' + $(this).attr('data-id')).remove();
        $('#deletePhoto_' + $(this).attr('data-id')).remove();

        var go_url = $('#go-url').val();

        $.ajax({
            type: "GET",
            url: go_url + /imagen/ + $(this).attr('data-value') + '/eliminar',
            async: false,
            success: function(data) {
                data = JSON.parse(data);
            },

        });
    });

    $('#removeImg').on('click', function(){
        var src =  $('#cover_holder').val();
        $('#cover').attr('src', src);
        $('#cover_image').attr('value', src);
    });

    if($('#uploadMedia').length) {
        var img = new Dropzone('#uploadMedia', {
            acceptedFiles: 'image/jpeg,image/png',
            paramName: 'upload_file',
            maxFiles: 1,
            uploadMultiple: false,
            processing: function (file, response) {
                $('#excel-loader').css('display', 'block');
            },
            success: function (file, response) {
                $('#excel-loader').css('display', 'none');
                $('#cover_image').val(JSON.parse(response));
                $('#cover').attr('src', JSON.parse(response));
                $('#media-info').css('display', 'none');
            },
            error: function (file, error, xhr) {
                $('#excel-loader').css('display', 'none');
                $('#error-upload').css('display', 'block');
            }
        });
    }

});

$(window).scroll(function(){ });
$(window).resize(function(){ });
