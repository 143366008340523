/*globals $*/
'use strict';

const getValidationFunctions = () => import("./validate");

$( document ).ready(function() {

    /*General*/
    if($('#defaultOpen').length > 0) {
        document.getElementById("defaultOpen").click();
    }


    /*Form Artist*/
    $('#checkArtist').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateArtistForm();
        });
    });

    if($('[id^="delete_element-"]')){
        if ($('[id^="delete_element-"]').on('click', function(){
            var url = $(this).attr('data-url');
            $('#element_delete_link').attr("href", url);
        }));
    }

    $('input[name=equipment]').on('change', function () {
        var value = $('input[name=equipment]:checked').val();

        if(value == '1'){
            $('#section_equipment_description').removeClass('hidden');
        }else{
            $('#section_equipment_description').addClass('hidden');
        }
    });

    $('#addOtherVideo').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateVideoForm();
        });
    });

    $('#addOtherMember').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateMemberForm();
        });
    });

    if($('#artist_members').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteMember")){
            $('#member-item-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('#addContract').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateContractForm();
        });
    });


    /*Common*/
    $('#addOtherGenre').on('click', function(e){
        e.preventDefault();

        $('#otherGenre').removeClass('hidden');
        $('#addOtherGenre').addClass('hidden');

    });


    /*Releases*/
    $('#checkRelease').on('click', function(e){
        $('#status').val('pending');
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateReleaseForm();
        });
    });

    $('#checkReleaseDraft').on('click', function(e){
        $('#status').val('draft');
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateReleaseForm();
        });
    });

    $('#add_other_principal_artist').on('click', function(e){
        if($('#add_other_principal_artist_section').hasClass('hidden')) {
            $('#add_other_principal_artist_section').removeClass('hidden')
        }else{
            $('#add_other_principal_artist_section').addClass('hidden')
        }
    });

    $('#addOtherArtist').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOtherArtistForm();
        });
    });

    $('#add_featuring_artist').on('click', function(e){
        if($('#add_featuring_artist_section').hasClass('hidden')) {
            $('#add_featuring_artist_section').removeClass('hidden')
        }else{
            $('#add_featuring_artist_section').addClass('hidden')
        }
    });

    if($('#other_artists').on('click', 'a', function(e){
        var id = this.id;
        if(id.match("^deleteOtherArtist")){
            $('#other_principal_artist-section-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('#addFeaturingArtist').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateFeaturingArtistForm();
        });
    });

    if($('#featuring_artists').on('click', 'a', function(e){
        var id = this.id;
        if(id.match("^deleteFeaturingArtist")){
            $('#featuring_artist-section-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('#addOtherTrack').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateTrackForm();
        });
    });

    $('#checkTrack').on('click', function(e){
        $('#status').val('pending');
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateTrackForm();
        });
    });

    $('#checkTrackDraft').on('click', function(e){
        $('#status').val('draft');
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateTrackForm();
        });
    });

    if($('#tracks').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteTrack")){
            $('#track-item-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('.openMedia').on('click', function(){
        $('#media-info').css('display', 'block');
    });

    $('#media-section-close').on('click', function() {
        $('#media-info').css('display', 'none');
    });

    $('#addOtherConcert').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateConcertForm();
        });
    });

    if($('#concerts').on('click', 'a', function(e){
        var id = this.id;
        if(id.match("^deleteConcert")){
            $('#concert-section-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));

    $('input[name=official_video]').on('change', function () {
        var value = $('input[name=official_video]:checked').val();

        if(value == '1'){
            $('#section_official_video_date').removeClass('hidden');
        }else{
            $('#section_official_video_date').addClass('hidden');
        }
    });

    $('#add_other_right').on('click', function(e){
        if($('#add_other_right_section').hasClass('hidden')) {
            $('#add_other_right_section').removeClass('hidden')
        }else{
            $('#add_other_right_section').addClass('hidden')
        }
    });

    $('#addOtherRights').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateRightForm();
        });
    });

    $('#addOtherRight').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOtherRightForm();
        });
    });

    /*Form Rightholder*/
    $('#checkRightholder').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateRightholderForm();
        });
    });

    $('#addOtherRightholder').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateOtherRightholderForm();
        });
    });

    if($('#rightholders').on('click', 'a', function(e){
        var id = this.id;
        if(id.match("^deleteRightholder")){
            $('#rightholder-section-' + $(this).attr('data-id')).remove();
        }
        // e.preventDefault();
    }));


    //Songs
    $('#addAuthor').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateAuthorForm();
        });
    });

    if($('#track_authors').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteAuthor")){
            $('#author-item-' + $(this).attr('data-id')).remove();
        }
    }));

    $('#addComposer').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateComposerForm();
        });
    });

    if($('#track_composers').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteComposer")){
            $('#composer-item-' + $(this).attr('data-id')).remove();
        }
    }));

    $('#addArranger').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateArrangerForm();
        });
    });

    if($('#track_arrangers').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteArranger")){
            $('#arranger-item-' + $(this).attr('data-id')).remove();
        }
    }));

    $('#addProducer').on('click', function(e){
        e.preventDefault();
        getValidationFunctions().then(validateModule => {
            validateModule.validateProducerForm();
        });
    });

    if($('#track_producers').on('click', 'a', function(e){
        var id = this.id
        if(id.match("^deleteProducer")){
            $('#producer-item-' + $(this).attr('data-id')).remove();
        }
    }));

    $('input[name=isrc_generation]').on('change', function () {
        var value = $('input[name=isrc_generation]:checked').val();

        if(value == '1'){
            $('#track_isrc').removeClass('hidden');
        }else{
            $('#track_isrc').addClass('hidden');
        }
    });

    /*Datasets*/
    $('#checkSalesReport').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#checkDatabaseTracks').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });

    $('#checkSplitRoyalties').on('click', function(e){
        e.preventDefault();
        var type = $(this).attr('data-type');
        $('#type').val(type);
        getValidationFunctions().then(validateModule => {
            validateModule.validateDatasetImport();
        });
    });
});

